<template>
  <div class="route-plan">
    <vxe-modal
      v-model="ModalVisible"
      width="45%"
      height="90%"
      class="my-modal"
      title="路线规划"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="false"
      :position="position"
      @close="handleCloseRoutePlanModal"
    >
      <div class="route-plan-header">
        <span>高亮</span>
        <a-switch default-checked @change="onChange" />
        <span>预计里程：{{ routePlan.estimatedMileage }}KM</span>
        <span>预计时间：{{ routePlan.estimatedTime }}min</span>
      </div>
      <a-form-model ref="form" :model="routePlan" :rules="rules" class="route-plan">
        <a-form-model-item label="路线名称" prop="routeName" class="route-plan-name">
          <a-input v-model="routePlan.routeName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="mapId" prop="mapId" class="route-plan-name">
          <a-input v-model="routePlan.mapId" placeholder="请输入" @change="handleMapIdChange" />
        </a-form-model-item>
        <a-form-model-item label="mapVersion" prop="mapVersion" class="route-plan-name">
          <a-input v-model="routePlan.mapVersion" placeholder="请输入" @change="handleMapVersionChange" />
        </a-form-model-item>
        <a-form-model-item label="温馨提示" class="route-plan-name">
          <span style="color:red;">选择的多条路径请依次相连，无中断</span>
        </a-form-model-item>
        <div class="route-plan-content-title">
          <span class="left"></span>
          <span class="route-plan-col">lane ID</span>
          <span class="route-plan-col">作业类型</span>
          <span class="route-plan-col">行驶位置</span>
          <span class="route-plan-col">速度kph</span>
          <span class="right"></span>
        </div>
        <draggable v-model="routePlan.raLanePlanningList" handle=".item-drag">
          <div class="route-plan-content-item" v-for="(item, index) in routePlan.raLanePlanningList" :key="index">
            <div class="left">{{ index + 1 }}</div>
            <a-form-model-item :prop="`raLanePlanningList[${index}].laneId`" class="route-plan-col">
              <a-input placeholder="" v-model="item.laneId" :disabled="true" />
            </a-form-model-item>
            <a-form-model-item :prop="`raLanePlanningList[${index}].taskType`" class="route-plan-col">
              <a-select
                default-value=""
                class="item-select"
                v-model="item.taskType"
                @change="handleTaskTypeChange($event, index)"
                :disabled="item.status == '1'"
              >
                <a-select-option
                  :value="taskItem.dictValue"
                  v-for="(taskItem, taskIndex) in taskTypeArray"
                  :key="taskIndex"
                >
                  {{ taskItem.dictLabel }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :prop="`raLanePlanningList[${index}].drivingPosition`" class="route-plan-col">
              <a-select
                default-value=""
                class="item-select"
                v-model="item.drivingPosition"
                :disabled="item.status == '1'"
              >
                <a-select-option
                  :value="posItem.dictValue"
                  v-for="(posItem, posIndex) in positionArray"
                  :key="posIndex"
                >
                  {{ posItem.dictLabel }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :prop="`raLanePlanningList[${index}].speedLimit`" class="route-plan-col">
              <a-input
                placeholder="请输入"
                v-model="item.speedLimit"
                :disabled="item.status == '1'"
                @change="handleSpeedChange"
              />
            </a-form-model-item>
            <div class="right">
              <!-- <a-icon type="drag" class="item-drag" /> -->
              <img :src="dragUrl" class="item-drag" v-if="index > 0" />
              <a-icon type="delete" class="item-delete" @click.stop="handleDeleteRoutePlan(index)" />
            </div>
          </div>
        </draggable>

        <div class="route-plan-content-item route-plan-content-remark">
          <div class="left">备注</div>
          <a-form-model-item class="route-plan-remark">
            <a-input v-model="routePlan.remark" placeholder="" />
          </a-form-model-item>
        </div>
      </a-form-model>
      <dialog-bottom-bar primary-text="确认" second-text="取消" @onConfirm="submitForm" @onCancel="handleCancel" />
    </vxe-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import {
  getRoutePlanning,
  addRoutePlanning,
  updateRoutePlanning,
  calculationMileageAndCostTime
} from '@/api/iot/routePlanning'
import Index from '@/components/FileUpload/index.vue'
import DialogBottomBar from '@/components/DialogBottomBar'
export default {
  name: 'RoutePlanModal',
  props: {
    routeId: {
      type: Number,
      default: () => {}
    },
    position: {
      type: String,
      required: true
    },
    mapLaneId: {
      type: String,
      default: () => {}
    }
  },
  components: { Index, DialogBottomBar, draggable },
  data() {
    // 节流控制
    this.handleSpeedChange = debounce(this.handleSpeedChange, 800)
    this.handleMapIdChange = debounce(this.handleMapIdChange, 500)
    this.handleMapVersionChange = debounce(this.handleMapVersionChange, 500)
    return {
      loading: false,
      // 表单参数
      form: {
        id: null,
        routeName: null,
        routeStartPoint: null,
        routeEndPoint: null,
        expectMileage: null,
        expectTime: null,
        remark: null,
        status: null
      },
      rules: {
        routeName: [{ required: true, message: '路线名称不能为空', trigger: 'blur' }],
        mapId: [{ required: true, message: 'mapId不能为空', trigger: 'blur' }],
        mapVersion: [{ required: true, message: 'mapVersion不能为空', trigger: 'blur' }]
      },

      routePlan: {
        expectMileage: 0,
        expectTime: 0,
        routeName: '',
        raLanePlanningList: [],
        mapId: '',
        mapVersion: ''
      },
      // 作业类型
      taskTypeArray: [],
      // 行驶位置
      positionArray: [],
      // 通过查询获取的Lane Id
      laneIdList: [],
      ModalVisible: true,
      dragUrl: require('@/assets/images/drag.png')
    }
  },
  filters: {},
  created() {
    console.log('created')
    this.loadDictData()
    this.getRoutePlanData(this.routeId)
  },
  computed: {
    ...mapGetters(['userTenant'])
  },
  watch: {
    mapLaneId(newVal, oldVal) {
      console.log('mapLaneId', newVal)
      this.handleAddRoutePlan(this.routePlan.raLanePlanningList.length - 1, newVal)
    },
    'routePlan.raLanePlanningList': {
      handler(newVal, oldVal) {
        console.log('监听到变化')
        this.getCalculationMileageAndCostTime()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.addRule(0)
  },
  methods: {
    // 取消按钮
    cancel() {
      this.reset()
    },
    // 表单重置
    reset() {
      this.routePlan = {
        expectMileage: 0,
        expectTime: 0,
        routeName: '',
        raLanePlanningList: [],
        mapId: '',
        mapVersion: ''
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
    },
    getRoutePlanData(id) {
      console.log('getRoutePlanData')
      this.reset()
      if (!id) {
        return
      }
      getRoutePlanning(id).then(response => {
        console.log('update', response.data)
        this.routePlan = response.data
        // 数据获取之后通知页面更新路线颜色
        this.$emit('UpdateRouteColor', this.routePlan.raLanePlanningList)
        this.$forceUpdate()
      })
    },
    // 重新获取里程和预计花费时间
    getCalculationMileageAndCostTime() {
      console.log('getCalculationMileageAndCostTime')
      const queryParam = { lanePlannings: [] }
      const raLanePlanningList = this.routePlan.raLanePlanningList
      raLanePlanningList.forEach(p => {
        if (p.speedLimit > 0) {
          queryParam.lanePlannings.push({
            laneId: p.laneId,
            speedLimit: p.speedLimit ? p.speedLimit : 0
          })
        }
      })
      calculationMileageAndCostTime(queryParam).then(res => {
        console.log('calculationMileageAndCostTime', res)
        this.routePlan.estimatedMileage = res.estimatedMileage
        this.routePlan.estimatedTime = res.estimatedTime
        this.$forceUpdate()
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      console.log('routePlan', this.routePlan)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.routePlan.id !== undefined && this.routePlan.id !== null) {
            updateRoutePlanning(this.routePlan).then(response => {
              this.$message.success('修改成功', 3)
              this.$emit('closeRoutePlanModal')
            })
          } else {
            addRoutePlanning(this.routePlan).then(response => {
              this.$message.success('新增成功', 3)
              this.$emit('closeRoutePlanModal')
            })
          }
        } else {
          return false
        }
      })
    },
    handleCloseRoutePlanModal() {
      this.$emit('closeRoutePlanModal')
    },
    onChange(checked) {
      console.log(`a-switch to ${checked}`)
    },
    addRule(index) {
      this.rules[`raLanePlanningList[${index}].laneId`] = [{ required: true, message: '不能为空', trigger: 'blur' }]
      this.rules[`raLanePlanningList[${index}].taskType`] = [
        { required: true, message: '类型不能为空', trigger: 'blur' }
      ]
      this.rules[`raLanePlanningList[${index}].drivingPosition`] = [
        { required: true, message: '位置不能为空', trigger: 'blur' }
      ]
      this.rules[`raLanePlanningList[${index}].speedLimit`] = [
        { required: true, message: '速度不能为空', trigger: 'blur' }
      ]
    },
    handleAddRoutePlan(laneId = '') {
      const index = this.routePlan.raLanePlanningList.length - 1
      const { taskType, drivingPosition, speedLimit } =
        this.routePlan.raLanePlanningList.length > 0 ? this.routePlan.raLanePlanningList[index] : {}

      this.routePlan.raLanePlanningList.splice(index + 1, 0, {
        laneId: laneId,
        taskType: taskType,
        drivingPosition: drivingPosition,
        speedLimit: speedLimit,
        status: 0
      })
      this.addRule(this.routePlan.raLanePlanningList.length - 1)
    },
    handleDeleteRoutePlan(index) {
      if (index < this.routePlan.raLanePlanningList.length) {
        // 先删除校验规则
        const item = this.routePlan.raLanePlanningList[index]
        const laneId = item.laneId
        for (const fieldName in item) {
          const key = `raLanePlanningList[${index}].${fieldName}`
          const rules = this.rules
          for (const r in rules) {
            if (r === key) {
              delete this.rules[key]
              break
            }
          }
        }
        this.routePlan.raLanePlanningList.splice(index, 1)
        this.$emit('onDeleteLaneId', laneId)
      }
    },
    handleCancel() {
      this.$emit('closeRoutePlanModal')
    },
    // 加载字典列表数据
    loadDictData() {
      this.getDicts('iot_lane_taskType').then(response => {
        this.taskTypeArray = response.data
      })
      this.getDicts('iot_lane_drivingPosition').then(response => {
        this.positionArray = response.data
      })
    },
    handleTaskTypeChange(val, index) {
      console.log('handleTaskTypeChange', index)
      const tmpTaskTypeArray = this.taskTypeArray.filter(t => t.dictValue === val)
      let speedLimit = 10
      if (tmpTaskTypeArray.length === 1) {
        const taskTypeName = tmpTaskTypeArray[0].dictLabel
        if (taskTypeName === '空驶') {
          speedLimit = 30
        }
        this.routePlan.raLanePlanningList[index].speedLimit = speedLimit
      }
    },
    handleSpeedChange(e) {
      console.log('handleSpeedChange', e)
    },
    handleMapIdChange(e) {
      const mapIdValue = e.target.value
      if (mapIdValue && this.routePlan.mapVersion) {
        this.$emit('onMapIdChange', mapIdValue, this.routePlan.mapVersion)
      }
    },
    handleMapVersionChange(e) {
      const mapVersion = e.target.value
      if (mapVersion && this.routePlan.mapId) {
        this.$emit('onMapVersionChange', this.routePlan.mapId, mapVersion)
      }
    }
  }
}
</script>

<style lang="less">
.route-plan {
  width: 100%;
  // height: calc(100vh - 20px);
  .bm-view {
    width: 100%;
    height: 100%;
    border: 1px solid #013a07;
  }
  .vxe-modal--wrapper .vxe-modal--content > div {
    height: auto;
  }
}
.route-plan-header {
  span {
    margin: 0 10px;
  }
}
.route-plan-name {
  display: flex;
  margin-top: 10px;
  input {
    width: 400px;
  }
}

.route-plan-content-title,
.route-plan-content-item {
  display: flex;
  .route-plan-col {
    width: 25% !important;
    margin: 0 3px;
    text-align: center;
  }
  > .left {
    flex: 0 0 35px;
    min-width: 35px;
    text-align: right;
    padding-right: 3px;
  }
  > .right {
    flex: 0 0 85px;
    min-width: 85px;
    display: flex;
  }
}

.route-plan-content-title {
  margin-top: 20px;
  .route-plan-col {
    font-weight: bold;
    text-align: center;
  }
}

.route-plan-content-item {
  > .left {
    height: 40px;
    line-height: 40px;
  }
  > .right {
    .item-drag,
    .item-delete {
      font-size: 25px;
      margin: 0 3px;
      height: 40px;
      line-height: 40px;
    }
  }
}
.item-delete {
  margin-top: 2px !important;
}
.item-drag:hover {
  cursor: pointer;
}

.route-plan-content-item.route-plan-content-remark {
  margin-top: 20px;
}
.route-plan-remark {
  width: 100%;
  margin: 0 4px;
}
.bottom-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  > .left {
    margin-right: 5px;
  }
  .right {
    margin-left: 5px;
  }
}
.route-plan {
  .ant-form-item-label {
    min-width: 100px !important;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
