var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "route-plan" },
    [
      _c(
        "vxe-modal",
        {
          staticClass: "my-modal",
          attrs: {
            width: "45%",
            height: "90%",
            title: "路线规划",
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: false,
            position: _vm.position,
          },
          on: { close: _vm.handleCloseRoutePlanModal },
          model: {
            value: _vm.ModalVisible,
            callback: function ($$v) {
              _vm.ModalVisible = $$v
            },
            expression: "ModalVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "route-plan-header" },
            [
              _c("span", [_vm._v("高亮")]),
              _c("a-switch", {
                attrs: { "default-checked": "" },
                on: { change: _vm.onChange },
              }),
              _c("span", [
                _vm._v(
                  "预计里程：" + _vm._s(_vm.routePlan.estimatedMileage) + "KM"
                ),
              ]),
              _c("span", [
                _vm._v(
                  "预计时间：" + _vm._s(_vm.routePlan.estimatedTime) + "min"
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-form-model",
            {
              ref: "form",
              staticClass: "route-plan",
              attrs: { model: _vm.routePlan, rules: _vm.rules },
            },
            [
              _c(
                "a-form-model-item",
                {
                  staticClass: "route-plan-name",
                  attrs: { label: "路线名称", prop: "routeName" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.routePlan.routeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.routePlan, "routeName", $$v)
                      },
                      expression: "routePlan.routeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "route-plan-name",
                  attrs: { label: "mapId", prop: "mapId" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    on: { change: _vm.handleMapIdChange },
                    model: {
                      value: _vm.routePlan.mapId,
                      callback: function ($$v) {
                        _vm.$set(_vm.routePlan, "mapId", $$v)
                      },
                      expression: "routePlan.mapId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "route-plan-name",
                  attrs: { label: "mapVersion", prop: "mapVersion" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    on: { change: _vm.handleMapVersionChange },
                    model: {
                      value: _vm.routePlan.mapVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.routePlan, "mapVersion", $$v)
                      },
                      expression: "routePlan.mapVersion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "route-plan-name",
                  attrs: { label: "温馨提示" },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("选择的多条路径请依次相连，无中断"),
                  ]),
                ]
              ),
              _c("div", { staticClass: "route-plan-content-title" }, [
                _c("span", { staticClass: "left" }),
                _c("span", { staticClass: "route-plan-col" }, [
                  _vm._v("lane ID"),
                ]),
                _c("span", { staticClass: "route-plan-col" }, [
                  _vm._v("作业类型"),
                ]),
                _c("span", { staticClass: "route-plan-col" }, [
                  _vm._v("行驶位置"),
                ]),
                _c("span", { staticClass: "route-plan-col" }, [
                  _vm._v("速度kph"),
                ]),
                _c("span", { staticClass: "right" }),
              ]),
              _c(
                "draggable",
                {
                  attrs: { handle: ".item-drag" },
                  model: {
                    value: _vm.routePlan.raLanePlanningList,
                    callback: function ($$v) {
                      _vm.$set(_vm.routePlan, "raLanePlanningList", $$v)
                    },
                    expression: "routePlan.raLanePlanningList",
                  },
                },
                _vm._l(
                  _vm.routePlan.raLanePlanningList,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "route-plan-content-item" },
                      [
                        _c("div", { staticClass: "left" }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "route-plan-col",
                            attrs: {
                              prop: "raLanePlanningList[" + index + "].laneId",
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "", disabled: true },
                              model: {
                                value: item.laneId,
                                callback: function ($$v) {
                                  _vm.$set(item, "laneId", $$v)
                                },
                                expression: "item.laneId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "route-plan-col",
                            attrs: {
                              prop:
                                "raLanePlanningList[" + index + "].taskType",
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "item-select",
                                attrs: {
                                  "default-value": "",
                                  disabled: item.status == "1",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleTaskTypeChange(
                                      $event,
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: item.taskType,
                                  callback: function ($$v) {
                                    _vm.$set(item, "taskType", $$v)
                                  },
                                  expression: "item.taskType",
                                },
                              },
                              _vm._l(
                                _vm.taskTypeArray,
                                function (taskItem, taskIndex) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: taskIndex,
                                      attrs: { value: taskItem.dictValue },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(taskItem.dictLabel) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "route-plan-col",
                            attrs: {
                              prop:
                                "raLanePlanningList[" +
                                index +
                                "].drivingPosition",
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "item-select",
                                attrs: {
                                  "default-value": "",
                                  disabled: item.status == "1",
                                },
                                model: {
                                  value: item.drivingPosition,
                                  callback: function ($$v) {
                                    _vm.$set(item, "drivingPosition", $$v)
                                  },
                                  expression: "item.drivingPosition",
                                },
                              },
                              _vm._l(
                                _vm.positionArray,
                                function (posItem, posIndex) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: posIndex,
                                      attrs: { value: posItem.dictValue },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(posItem.dictLabel) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "route-plan-col",
                            attrs: {
                              prop:
                                "raLanePlanningList[" + index + "].speedLimit",
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: {
                                placeholder: "请输入",
                                disabled: item.status == "1",
                              },
                              on: { change: _vm.handleSpeedChange },
                              model: {
                                value: item.speedLimit,
                                callback: function ($$v) {
                                  _vm.$set(item, "speedLimit", $$v)
                                },
                                expression: "item.speedLimit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            index > 0
                              ? _c("img", {
                                  staticClass: "item-drag",
                                  attrs: { src: _vm.dragUrl },
                                })
                              : _vm._e(),
                            _c("a-icon", {
                              staticClass: "item-delete",
                              attrs: { type: "delete" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDeleteRoutePlan(index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                {
                  staticClass:
                    "route-plan-content-item route-plan-content-remark",
                },
                [
                  _c("div", { staticClass: "left" }, [_vm._v("备注")]),
                  _c(
                    "a-form-model-item",
                    { staticClass: "route-plan-remark" },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.routePlan.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.routePlan, "remark", $$v)
                          },
                          expression: "routePlan.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("dialog-bottom-bar", {
            attrs: { "primary-text": "确认", "second-text": "取消" },
            on: { onConfirm: _vm.submitForm, onCancel: _vm.handleCancel },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }